import { BlockContainer } from '@components/Blocks';
import Image from '@components/Image';
import LinkList from '@components/LinkList';
import { createBlock } from '@lib/features/blocks/blocks';
import Box from '@primitive/Box';
import Txt from '@primitive/Txt';
import * as style from './CtaBlock.css';

const Cta1Block = createBlock<'blocks_cta1_BlockType'>(
  ({ heading, content, linkCtaMultiple, image }) => {
    return (
      <BlockContainer className={style.cta1Root}>
        <div className={style.cta1Inner}>
          <Image
            transform="landscapeLgCrop"
            // ratio="landscapeWide"
            ShimProps={{ className: style.cta1ImageWrap }}
            alt=""
            {...image}
          />

          <Box className={style.cta1Content} rounded elevation colorSet="paper" paper>
            <Txt variant="h4" as="h2" autoMargin>
              {heading}
            </Txt>
            <Txt html>{content}</Txt>
            <LinkList className={style.cta1Links} cx={{ mT: 'md' }} items={linkCtaMultiple} />
          </Box>
        </div>
      </BlockContainer>
    );
  }
);

export default Cta1Block;
